import React, { useState } from "react";
import ProductDetail from "./ProductDetail";
import ProductSize from "./ProductSize";
import ProductColor from "./ProductColor";
import ProductImage from "./ProductImage";
import { useSelector } from "react-redux";
export default function AddProduct() {
  const [productList, setProductList] = useState(null);
  const [sizeDetails, setSizeDetails] = useState(null);

  const [activeButton, setActiveButton] = useState("product");
  const { token } = useSelector((state) => state.auth);
  
  console.log(productList);
  const sendProductDetails = async (productDetails) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addProduct");
      formDataToSend.append("token", token); 
      formDataToSend.append("product_name", productList.name);
      formDataToSend.append("product_desc", productList.description);
      formDataToSend.append("Category_Id", productList.category);
      formDataToSend.append("product_code","asda");

      // Add more fields as needed

      // Log the data before sending
      const response = await axios.post("https://jutiepie.in/api/product.php", formDataToSend);

      // Handle the API response here
      console.log("API response:", response.data);

      // Reset or navigate to another page upon successful API call
      setProductList(null);
      setActiveButton("product");

    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  const sizeproductdeatil = async (ProductSize) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddSize");
      formDataToSend.append("token", token); 
      formDataToSend.append("product_width", ProductSize.product_width);
      formDataToSend.append("product_height", ProductSize.product_height);
      formDataToSend.append("product_id", ProductSize.product_id);
    

      // Add more fields as needed

      // Log the data before sending
      const response = await axios.post("https://jutiepie.in/api/product.php", formDataToSend);

      // Handle the API response here
      console.log("API response:", response.data);

      // Reset or navigate to another page upon successful API call
      setProductList(null);
      setActiveButton("product");

    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  const colorproductdeatil = async (ProductColor) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddColor");
      formDataToSend.append("token", token); 
      formDataToSend.append("color_code", ProductColor.color_code);
      formDataToSend.append("product_qty", ProductColor.product_qty);
      formDataToSend.append("product_feature", ProductColor.product_feature);
      formDataToSend.append("product_price", ProductColor.product_price);
      formDataToSend.append("color_name", ProductColor.color_name);
     
    

      // Add more fields as needed

      // Log the data before sending
      const response = await axios.post("https://jutiepie.in/api/product.php", formDataToSend);

      // Handle the API response here
      console.log("API response:", response.data);

      // Reset or navigate to another page upon successful API call
      setProductList(null);
      setActiveButton("product");

    } catch (error) {
      console.error("Error sending product details to the API:", error);
      // Handle errors, display an error message, or take appropriate action.
    }
  };
  return (
    <div>
      {activeButton === "product" && (
        <ProductDetail
          setProductList={setProductList}
          productList={productList}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        />
      )}
      {activeButton === "size" && (
        <ProductSize
          setProductList={setProductList}
          productList={productList}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setSizeDetails={setSizeDetails}
        />
      )}

      {activeButton === "color" && (
        <ProductColor
          setProductList={setProductList}
          productList={productList}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          sizeDetails={sizeDetails}
        />
      )}

      {activeButton === "image" && (
        <ProductImage
          setProductList={setProductList}
          productList={productList}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          
        />
      )}
    </div>
  );
}
