import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip, message, Spin, Modal } from "antd"; // Import Modal
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import { useSelector } from "react-redux";
import axios from "axios";

export default function FoodItems() {
  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [categoryMap, setCategoryMap] = useState({});

  const { drawerData, setDrawerData } = useDrawer();
  const { token } = useSelector((state) => state.auth);

  const fetchCategoryName = async (categoryId) => {
    if (categoryMap[categoryId]) {
      // Return cached value if available
      return categoryMap[categoryId];
    }
    try {
      const formData = new FormData();
      formData.append("action", "getCategoryName");
      formData.append("Category_Id", categoryId);

      const response = await axios.post("https://jutiepie.in/api/category.php", formData);

      if (response.data?.Category_Name) {
        setCategoryMap((prev) => ({
          ...prev,
          [categoryId]: response.data.Category_Name,
        }));
        return response.data.Category_Name;
      }
    } catch (error) {
      console.error(`Error fetching category name for ID ${categoryId}:`, error);
      message.error("Failed to fetch category name.");
    }
    return "Unknown"; // Fallback if API call fails
  };

  const fetchData = async (page, limit) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://jutiepie.in/api/product.php?action=productFetch&category_slug=all&page=${page}&limit=${limit}`
      );

      if (response.data) {
        const { products, pagination } = response.data;

        const updatedProducts = await Promise.all(
          products.map(async (item) => {
            const categoryName = await fetchCategoryName(item.product.Category_Id);
            return {
              ...item.product,
              Category_Name: categoryName,
              total_sold: item.color?.product_qty || 0,
              out_of_stock: item.color?.product_qty > 0 ? "1" : "0",
            };
          })
        );

        setFetchList(updatedProducts);
        setTotalData(Number(pagination.total_records));
        setCurrentPage(pagination.current_page);
        setPageSize(pagination.limit);
      }
    } catch (error) {
      console.error("Error fetching product data", error);
      message.error("Failed to fetch product data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "packageAdd",
      title: "Product Add",
      ID: null,
    });
  };

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "Updateproduct",
      title: "Update Product",
      value: value,
    });
  };

  const showDeleteConfirm = (productId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this product?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => handleDelete(productId),
    });
  };

  const handleDelete = async (productId) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("token", token);
      formData.append("action", "remove");
      formData.append("product_id", productId);

      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formData
      );

      if (response.data.success) {
        message.success("Product deleted successfully");
        fetchData(currentPage, pageSize);
      } else {
        message.error(response.data.message || "Failed to delete product");
      }
    } catch (error) {
      message.error("Failed to delete product");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "Product Id",
      dataIndex: "Product_Id",
      key: "Product_Id",
    },
    {
      title: "Category Name",
      dataIndex: "Category_Name",
      key: "Category_Name",
    },
    {
      title: "Product Name",
      dataIndex: "Product_Name",
      key: "Product_Name",
    },
    {
      title: "Product Description",
      dataIndex: "Product_Desc",
      key: "Product_Desc",
    },
    {
      title: "Total Sold",
      dataIndex: "total_sold",
      key: "total_sold",
    },
    {
      title: "Stock Status",
      dataIndex: "out_of_stock",
      key: "out_of_stock",
      render: (out_of_stock) => (
        out_of_stock === "0" ? (
          <Tooltip title="Out of Stock">
            <CloseCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        ) : (
          <Tooltip title="In Stock">
            <CheckCircleOutlined style={{ color: "green" }} />
          </Tooltip>
        )
      ),
    },
    {
      title: "Edit",
      dataIndex: "Edit",
      key: "Edit",
      render: (_, record) => (
        <Tooltip title="View Product Details" placement="bottom">
          <Button className="otherButtons" onClick={() => handleDrawerUpdate(record)}>
            Edit
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      render: (_, record) => (
        <Tooltip title="Delete Product" placement="bottom">
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            size="large"
            onClick={() => showDeleteConfirm(record.Product_Id)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <TitleComp
        name="Product List"
        handleDrawerAdd={handleDrawerAdd}
        totalData={totalData}
        type="Products"
      />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={fetchList}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
          }}
          rowKey="Product_Id"
          onChange={handleTableChange}
        />
      </Spin>
      <DrawerComp />
    </div>
  );
}
